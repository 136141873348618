// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require.context('../../../vendor/assets/maxia/assets/svg', true)
require.context('../../../vendor/assets/maxia/assets/icons/bootstrap-icons', true)
require.context('../../../vendor/assets/maxia/assets/svg-new', true)
require.context('../../../vendor/assets/maxia/assets/img', true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import '../../../vendor/assets/maxia/js/bootstrap.bundle.min'
import '../../../vendor/assets/maxia/js/svg-inject.min'

import ApexCharts from  '../../../vendor/assets/maxia/js/apexcharts.min'
import Swiper from '../../../vendor/assets/maxia/js/swiper-bundle.min'
import Swiperv8 from '../../../vendor/assets/maxia/js/swiperv8-bundle.min'
import 'summernote/dist/summernote.min';

import $ from 'jquery';

// FUNCTIONS
window.Swiper = Swiper
window.Swiperv8 = Swiperv8
window.ApexCharts = ApexCharts
window.bootstrap = require('../../../vendor/assets/maxia/js/bootstrap.bundle.min')

window.jQuery = $
window.$ = $

import '../styles/maxia.scss'


Rails.start()
Turbolinks.start()
ActiveStorage.start()
